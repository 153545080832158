$color1: #88bdbc;
$color2: #254e58;
$color3: #4f4a41;
$color4: #112d32;
$color5: #6e6658;
$color6: #fdfdfd;
// $colorBackground: #004149;
// $colorTextOnBackground: #d3d100;

$colorSoftBackground: rgba(0, 65, 73, 0.3);
$colorBackground: rgb(60, 75, 100);
$colorTextOnBackground: #b9bec5;
$colorTextOnSoftBackground: #000000;
$colorTextOnBackgroundDarker: #a6a308;



$severity1: rgba(37, 234, 217, 0.75);
$severity2: rgba(253, 253, 0, 0.75);
$severity3: rgba(255, 212, 56, 0.75);
$severity4: rgba(255, 57, 50, 0.75);
$severity5: rgb(52, 12, 10, 0.75);

$severity1-light: rgba(37, 234, 217, 0.35);
$severity2-light: rgba(253, 253, 0, 0.35);
$severity3-light: rgba(255, 212, 56, 0.35);
$severity4-light: rgba(255, 57, 50, 0.35);
$severity5-light: rgba(52, 12, 10, 0.35);

$glownoattention: rgba(201,201,201,0.47);
$glowattention: rgba(255, 57, 50, 0.47);
$glowmaybeattention: rgba(255, 207, 50, 0.47);

$overlay:rgba(201, 201, 201, 0.183);
$a: #1571ce;

.xx {
    font-weight: normal
}