@import '../../common.scss';

.report-timeline {
    height: 100%;
    overflow-y: scroll;
    border-radius: 15px;
}

.report-details {
    width: 80%;
    height: 100%;
    border-radius: 15px;
    overflow-y: scroll;
}