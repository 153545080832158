.problemSev {
    width: '100%';
    border-radius: 10;
}
.problemSev1 {
    // background: rgba(0, 255, 255, 0.6) !important;
    border: 2px solid cyan;
}
.problemSev2 {
    // background: rgba(153, 205, 50, 0.6) !important;
    border: 2px solid greenyellow;
}
.problemSev3 {
    // background: rgba(255, 166, 0, 0.6) !important;
    border: 2px solid orange;
}
.problemSev4 {
    // background: rgba(255, 0, 0, 0.6) !important;
    border: 2px solid red;
}

.problemCountIndicator .MuiChip-root {
    position: relative;
    bottom: 15px;
}