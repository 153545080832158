@import "../../common.scss";

.alertrow:hover {
    color: black;
    font-weight: bolder; 
}
.alertrow-security {
    background: $severity5 !important;
    color: white !important; 
}
.alertrow-critical {
    background: $severity4 !important;
    color: black !important;
}
.alertrow-major {
    background: $severity3 !important;
    color: black !important;
}
.alertrow-minor {
    background: $severity2 !important;
    color: black !important;
}
.alertrow-warning {
    background: $severity1 !important;
    color: black !important;
}
.alertrow-normal {
    background: rgb(150, 248, 75) !important;
    color: black !important;
}
.alertrow-detailed {
    margin-left: 20px;
    margin-top: 10px;
    margin-bottom: 10px;
}