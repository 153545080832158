@import '../../common.scss';

.loader {
    border: 8px solid $overlay;
    border-top: 8px solid $colorBackground;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spinloader 2s linear infinite;
    align-items: center;
    vertical-align: middle;
    margin-top: 25vh;
}
.loader img:nth-child(1){
    //margin-top: 100px;
    padding-top: -15px;
    height : 50px;
    width : 50px;
    border-radius: 50%;
    animation: spinlogo 15s linear infinite;
}
@keyframes spinloader {
    0% { transform: rotate(-60deg); }
    100% { transform: rotate(300deg); }
}
@keyframes spinlogo {
    0% { transform: rotate(-300deg); }
    100% { transform: rotate(60deg); }
}

.overlay {
    backdrop-filter: blur(2px);
    background-color: $overlay;
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    position:absolute;
    top:0;
    left: 0;
    z-index: 10000;
}