@import "../../common.scss";

.pagePaper {

    height: max(88vh, 600px);
    width: 100%;
    border-radius: 15px;
    backdrop-filter: blur(25px);
    .title {
        color: $colorTextOnBackground !important
    }
}

.page-paper-title {
    color: $colorTextOnSoftBackground
}