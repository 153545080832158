.dot {
    height: 25px;
    width: 25px;
    background-color: #bbb;
    border: 2px solid rgb(25, 25, 25, 10%);
    border-radius: 50%;
    display: inline-block;
    box-shadow: -1px -1px 1px 1px rgb(25, 25, 25, 25%);
  }
.dot.greendot {
    background-color: green;
  }
.dot.amberdot {
    background-color: orange;
  }
.dot.reddot {
    background-color: red;
  }

.quadratura-row.ok {
    color:black;
}
.quadratura-row.ko {
    color:red;
}

.dollaroSimpleTable {
    width: 100%;
    border-collapse: collapse;
    font-size: small;
}
.dollaroSimpleTable thead > * td {
    font-weight: bold;
}
.dollaroSimpleTable td {
    border: 1px solid black;
}

.borderTable {
    border: '1px solid black'
}