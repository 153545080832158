@import '../../common.scss';

.filters {
    min-height: 20vh;
    background-color: $glownoattention;
    box-shadow: 2px 2px 2px 2px $glownoattention;
    border-radius: 5px;
    width: 100%;
    vertical-align: middle;
    padding: 15px;

    .MuiSvgIcon-root {
        color: $colorBackground !important;
    }

    .MuiInputLabel-root {
        color: $colorTextOnBackgroundDarker !important;
        font-weight: bold;
    }
    .MuiInputBase-input {
        color: $colorTextOnBackgroundDarker !important;
        border-color: $colorBackground;
    }
    .MuiFormControlLabel-label {
        color: $colorTextOnBackgroundDarker !important;
    }
}