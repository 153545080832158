@import '../../common.scss';

.widget {
    display: flex;
    flex: 1px;
    justify-content: space-between;
    padding: 10px;
    -webkit-box-shadow: 2px 2px 5px 1px $glownoattention;
    box-shadow: 2px 2px 5px 1px $glownoattention;
    border-radius: 10px;
    height: 25vh;
    background-color: rgba(255,255,255,0.15);

   

    .checkName {
        font-weight: bold;
        font-size: 24px;
        color: $colorTextOnBackground;
    }
    .customer {
        font-weight: bold;
        font-size: 14px;
        color: $colorTextOnBackgroundDarker;
    }

    .lastAmountAmount {
        font-weight: bold;
        font-size: 20px;
        color: $colorTextOnBackground;
    }
    .lastAmountUnit {
        font-weight: normal;
        font-size: 16px;
        color: $colorTextOnBackgroundDarker;
    }

    .statusText {
        font-size: 12px;
        font-weight: 300;
    }

    .link {
        width: max-content;
        font-size: 12px;
        border-bottom: 1px solid grey;
    }

    .percentage {
        display: flex;
        align-items: center;
        font-style: 14px;

        &.positive {
            color: green;
        }
        &.negative {
            color: red;
        }
    }

    .icon {
        font-size: 18px;
        padding: 5px;
        border-radius: 5px;
        align-self: flex-end;
    }
}

.widget.ko {
    background-color: $glowattention;
    box-shadow: 2px 2px 5px 1px $glowattention;
}
.widget.missing {
    background-color: $glowmaybeattention;

    box-shadow: 2px 2px 5px 1px $glowmaybeattention;
}